<template>
  <v-card>
    <v-toolbar dark color="primary" dense class="mb-5">
      <v-btn icon dark @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Categoria Compliance</v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-form ref="form">
      <v-row justify="start" no-gutters class="pl-5 mt-0">
        <v-col class="d-flex pr-5 ml-8 " lg="12" md="12" sm="12">
          <v-responsive max-width="344" >
            <v-text-field class="pt-5" v-model="category.title" outlined dense label="Categorias" :rules="$rules.required" required
              :items="category" item-text="title" item-value="id" />
          </v-responsive>
        </v-col>
        <v-row justify="center" align="center">
          <v-col class="d-flex pr-5 mt-4" lg="12" md="8" sm="12">

            <v-file-input v-model="imagesUpload" outlined label="Clique para adicionar imagens do tamanho 1024x768"
              prepend-icon="mdi-camera" required accept="image/jpeg, image/png" @change="onFilesSelected" />
          </v-col>
          <v-col class="pb-10">
            <gallery :images="imagesPreview" :rules="$rules.required" required @image-deleted="onDeleteImage" />
          </v-col>
        </v-row>
        <v-dialog v-model="dialogCropImage" persistent>
          <v-card>
            <div>
              <v-card-title class="text-h5">
                Recorte da imagem para tamanho 1024x768.
              </v-card-title>
            </div>
            <div>
              <v-card-text>
                Para melhor visualização da imagem no Informa Site, recomendamos
                utilizar o tamanho da imagem em 1024x768. <br>
                Utilize o "scroll" do mouse para ajustar a imagem.
                <br>
                <br>
                Clique e segure na imagem para arrastar e posicionar.
              </v-card-text>
            </div>
            <div style="padding-left: 600px">
              <resize-image ref="image" :target-width="resizeImageParams.targetWidth"
                :target-height="resizeImageParams.targetHeight"
                :min-aspect-ratio-value1="resizeImageParams.minAspectRatioValue1"
                :min-aspect-ratio-value2="resizeImageParams.minAspectRatioValue2"
                :max-aspect-ratio-value1="resizeImageParams.maxAspectRatioValue1"
                :max-aspect-ratio-value2="resizeImageParams.maxAspectRatioValue2" :image-param="imagesPreview" />
            </div>
            <v-card-actions>
              <v-btn color="red darken-1" text @click="cancelCropImage()">
                Cancelar
              </v-btn>
              <v-btn color="green darken-1" text @click="cropImageOnConfirm">
                Confirmar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-form>
    <v-row class="mt-10" justify="center" no-gutters>
      <v-card-actions>
        <v-btn color="secondary" @click="close">
          Cancelar
        </v-btn>
        <v-btn v-if="loading === true" color="primary" loading @click="submit" />
        <v-btn v-else color="primary" @click="submit">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-row>
   
  </v-card>
</template>

<script>
import sanitize from 'sanitize-filename';
import { createFeaturedPhoto } from '@/services/photos-service';
import { image } from '../../../services/upload-service';
import ResizeImage from '../../../components/shared/ResizeImage.vue';
import imageFormater from '../../../utils/convert-image-to-file';
import ComplianceCategoryService from '../../../services/compliance-category.service';

export default {
  name: 'CategoryForm',

  components: {
    ResizeImage,
    'attachment-card': () => import('../../../components/shared/AttachmentCards.vue'),
  },

  props: {
    populateWith: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      category: {},
      linkFiles: [],
      dialogCropImage: false,
      imagesPreview: [],
      imagesUpload: null,
      updateButton: false,
      loading: false,
      resizeImageParams: {
        targetWidth: 1024,
        targetHeight: 768,
        minAspectRatioValue1: 4,
        minAspectRatioValue2: 3,
        maxAspectRatioValue1: 4,
        maxAspectRatioValue2: 3,
      },
      attcUpload: [],
      attcFile: [],
      modalConfirm: false,
    };
  },
  async created() {
    if (this.populateWith.id) {
      const { featuredImage } = this.populateWith;
      this.imagesPreview = featuredImage ? [featuredImage] : [];
      this.category = { ...this.populateWith };
      this.updateButton = true;
    }
  },
  methods: {
  
    close() {
      this.$emit('close-dialog');
    },

    onFilesSelected(files) {
      this.dialogCropImage = true;
      this.imagesPreview = [URL.createObjectURL(files)];
    },

    onDeleteImage(index) {
      this.imagesPreview.splice(index, 1);
      this.imagesUpload = null;

      this.category = { ...this.category, featuredImage: null };
    },

    async checkImagesUploadAndCreatePhoto() {
      if (this.imagesUpload) {
        const imageType = imageFormater.getMimeType(this.imagesPreview);
        const formattedTitle = sanitize(this.category.title);
        const imageFormatted = imageFormater.convertImageToFile(
          this.imagesPreview,
          `${formattedTitle.substring(0, 10)}.${imageType}`,
        );
        const photo = await createFeaturedPhoto(imageFormatted);
        this.category = { ...this.category, featuredImage: photo.link };
      }
    },



    cropImageOnConfirm() {
      const { coordinates, canvas } = this.$refs.image.$refs.cropper.getResult();
      this.coordinates = coordinates;
      const imageToArray = canvas.toDataURL();
      this.imagesPreview = [imageToArray];
      this.dialogCropImage = false;
    },

    cancelCropImage() {
      this.dialogCropImage = false;
      this.imagesUpload = null;
      this.imagesPreview = [];
    },

    async submit() {
      if (!this.$refs.form.validate()) {
        this.$toast.error('Verifique os campos e tente novamente');
        return;
      }

      try {
        this.loading = true;
        await this.checkImagesUploadAndCreatePhoto();
        if (this.populateWith.id) {
          await ComplianceCategoryService.updateCategory({ ...this.category });
          this.$emit('category-edited', { ...this.category });
          this.$toast.success('Categoria editada com sucesso');
        } else {
          await ComplianceCategoryService.createCategory({ ...this.category });
          this.$emit('category-added', { ...this.category });
          this.$toast.success('Categoria cadastrada com sucesso');
          this.clearForm();
        }
        this.loading = false;
        this.close();
      } catch (e) {
        this.$handleHttpError(e);
        this.loading = false;
      }
    },

    clearForm() {
      this.$refs.form.reset();
      this.category.text = null;
      this.imagesPreview = [];
      this.$refs.form.resetValidation();

    },

  },
};
</script>
